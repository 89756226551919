import {
  AutocompleteInput,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  DateInput,
  BooleanInput,
  TextField,
  SelectInput,
} from 'react-admin';
import Box from '@mui/material/Grid';
import React from 'react';

export default (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="userId" reference="users" perPage={10000}>
        <Box sx={{ minWidth: 300 }}>
          <AutocompleteInput optionText="email" />
        </Box>
      </ReferenceInput>
      <TextInput source="callerNumber" />
      <TextInput source="name" />
      <TextInput source="zohoTag" />
      <DateInput source="startWorkingDay" />
      <DateInput source="endWorkingDay" />
      <TextInput source="zohoAccount" />
      <TextInput source="role" />
      <BooleanInput source="visible" />
    </SimpleForm>
  </Create>
);
