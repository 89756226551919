import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  SelectField,
  SelectInput,
  TextField,
} from 'react-admin';
import CurrencyField from '../Custom/CurrencyField';

const Filter = [
  <SelectInput
    alwaysOn
    source="lastStage"
    choices={[
      { id: 'NEW', name: 'New' },
      { id: 'FULLY_PAID', name: 'Fully Paid' },
      { id: 'PARTIAL_PAID', name: 'Partial Paid' },
      { id: 'CANCELLED', name: 'Cancelled' },
    ]}
  />,
];
export default (props) => (
  <List filters={Filter} {...props} sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid rowClick={(id, basePath, record) => `/learningContracts/${record.id}/show`}>
      <TextField source="id" />

      <ReferenceField source="userId" reference="users" label="Student" link="show">
        <TextField source="name" />
      </ReferenceField>
      <CurrencyField source="totalFee" />
      <TextField source="totalLessons" />
      <TextField source="bonusLessons" />
      <CurrencyField source="discount" />
      <SelectField
        source="lastStage"
        choices={[
          { id: 'NEW', name: 'New' },
          { id: 'FULLY_PAID', name: 'Fully Paid' },
          { id: 'PARTIAL_PAID', name: 'Partial Paid' },
          { id: 'CANCELLED', name: 'Cancelled' },
        ]}
        optionText="name"
        optionValue="id"
      />
      <TextField source="type" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
