import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  Button,
  RecordContextProvider,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import { v4 as uuidv4 } from 'uuid';
import { Box, Rating, Typography } from '@mui/material';
import Config from '../../Config';

const axios = require('axios');

const StudentSurveyCreateButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const [teacherRating, setTeacherRating] = useState();
  const [programRating, setProgramRating] = useState();
  const [platformRating, setPlatformRating] = useState();
  const [serviceRating, setServiceRating] = useState();

  const [tutorList, setTutorList] = useState([]);

  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();
  const [create, { isLoading: isCreateLoading }] = useCreate('notes');

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleClick = () => {
    setShowDialog(true);
    setPlatformRating(null)
    setProgramRating(null)
    setServiceRating(null)
    setTeacherRating(null)
    
  };

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem('auth'));

    axios
      .post(
        `${Config.apiUrl}/tutor/list-tutors`,
        {},
        { headers: { Authorization: `Bearer ${auth.accessToken}` } },
      )
      .then((response) => {
        setTutorList(response.data.map((val) => ({ ...val, key: val.id })));
      })
      .catch((error) => {
        notify(error.message, 'error');
      });
  }, []);

  const handleSubmit = async (val) => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    try {
      setLoading(true);
      create(
        'studentExperienceSurveys',
        {
          data: {
            id: uuidv4(),
            studentId: record.id,
            ...val,
            serviceSatifiedLevel: serviceRating,
            teacherSatifiedLevel: teacherRating,
            programSatifiedLevel: programRating,
            platformSatifiedLevel: platformRating,
            enteredBy:  auth.user.id,
          },
        },
        {
          onSuccess: () => {
            notify('Create note successfully.', 'success');
            setShowDialog(false);
            setLoading(false);
            refresh();
          },
          onError: ({ err }) => {
            notify(err.message, 'error');
            setShowDialog(false);
            setLoading(false);
          },
        },
      );

      setShowDialog(false);
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const SaveToolbar = () => (
    <Toolbar style={{ justifyContent: 'flex-end' }}>
      <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
        <IconCancel />
      </Button>
      <SaveButton disabled={isLoading} />
    </Toolbar>
  );

  return (
    <>
      <Button onClick={handleClick}>Create Student Survey</Button>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create Student Survey"
      >
        <DialogTitle>Create Student Survey</DialogTitle>
        <RecordContextProvider>
          <SimpleForm onSubmit={handleSubmit} toolbar={<SaveToolbar />}>
            <Typography fontWeight={600} component="legend">
              Student Status
            </Typography>

            <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 2fr)', width: 1 }}>
              <TextInput source="learningStatus" validate={required()} />

              <TextInput source="parentAssessment" validate={required()} multiline />
              <TextInput source="studentOrientation" validate={required()} multiline />
            </Box>

            <Typography fontWeight={600} marginBottom={1} component="legend">
              Teacher
            </Typography>
            <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
              <AutocompleteInput
                source="tutorId"
                optionText="name"
                optionValue="tutorId"
                label="Tutor"
                key="key"
                choices={tutorList}
              />

              <Box>
                <Typography marginBottom={2} component="legend">
                  Teacher Satified Rating
                </Typography>
                <Rating
                  value={teacherRating}
                  name="teacherSatifiedLevel"
                  onChange={(event, newValue) => {
                    setTeacherRating(newValue);
                  }}
                />
              </Box>
              <TextInput source="teacherQuality" multiline />
              <TextInput source="teacherImprovement" multiline />
              <TextInput source="teacherGoodPoint" multiline />
            </Box>

            <Typography marginBottom={2} fontWeight={600} component="legend">
              Program
            </Typography>
            <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
              <Box>
                <Typography component="legend">Program Satified Rating</Typography>
                <Rating
                  value={programRating}
                  name="programSatifiedLevel"
                  onChange={(event, newValue) => {
                    setProgramRating(newValue);
                  }}
                />
              </Box>
              <TextInput source="programAssessment" multiline />
              <TextInput source="programGoodPoint" multiline />
              <TextInput source="programImprovement" multiline />
            </Box>

            <Typography marginBottom={2} fontWeight={600} component="legend">
              Platform
            </Typography>
            <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
              <Box>
                <Typography component="legend">Platform Satified Rating</Typography>
                <Rating
                  value={platformRating}
                  name="platformSatifiedLevel"
                  onChange={(event, newValue) => {
                    setPlatformRating(newValue);
                  }}
                />
              </Box>
              <TextInput source="platformAssessment" multiline />
              <TextInput source="platformGoodPoint" multiline />
              <TextInput source="platformImprovement" multiline />
            </Box>

            <Typography marginBottom={2} textAlign="center" fontWeight={600} component="legend">
              Service
            </Typography>
            <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
              <Box>
                <Typography component="legend">Service Satified Rating</Typography>
                <Rating
                  value={serviceRating}
                  name="serviceSatifiedLevel"
                  onChange={(event, newValue) => {
                    setServiceRating(newValue);
                  }}
                />
              </Box>
              <TextInput source="serviceAssessment" multiline />
              <TextInput source="serviceGoodPoint" multiline />
              <TextInput source="serviceImprovement" multiline />
            </Box>

            <TextInput fullWidth source="contribution" multiline />
            <TextInput fullWidth source="support" multiline required />
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

export default StudentSurveyCreateButton;
