import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  List,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  SelectInput,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import CurrencyField from '../Custom/CurrencyField';
import DepositButton from './DepositButton';
import WithdrawButton from './WithdrawButton';
import authProvider from '../../ReactAdmin/authProvider';
import TransferButton from './TransferButton';
import RetractButton from './RetractButton';
import RequestDepositButton from './RequestDepositButton';

const WalletShowActions = () => {
  const record = useRecordContext();
  const role = authProvider.getRole();
  return (
    <TopToolbar>
      {record && (
        <>
          {!role && [
            <EditButton />,
            <DepositButton walletId={record.id} amount={record.amount} />,
            <WithdrawButton walletId={record.id} amount={record.amount} />,
          ]}
          <RequestDepositButton walletId={record.id} amount={record.amount} />

          {role === 'lettutor_partner' && [
            <TransferButton walletId={record.id} />,
            <RetractButton walletId={record.id} />,
          ]}
        </>
      )}
    </TopToolbar>
  );
};

const TransactionFilters = [
  <SelectInput
    alwaysOn
    source="type"
    choices={[
      { id: 'buy', name: 'buy' },
      { id: 'sell', name: 'sell' },
      { id: 'send', name: 'send' },
      { id: 'deposit', name: 'deposit' },
      { id: 'contractPayment', name: 'contract payment' },
      { id: 'withdraw', name: 'withdraw' },
    ]}
  />,
];

const TransactionTab = () => {
  const record = useRecordContext();
  return record ? (
    <List
      actions={false}
      filters={TransactionFilters}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      filter={{ walletId: record.id }}
    >
      <Datagrid rowClick="show">
        <DateField source="createdAt" />
        <TextField source="type" />
        <CurrencyField source="price" />
        <CurrencyField source="revenue" />
        <CurrencyField source="balance" />
        <TextField source="note" />
        <ReferenceField source="saleByStaffId" reference="staffs">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="status" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List>
  ) : null;
};

export default (props) =>(
    <Show {...props} actions={<WalletShowActions />}>
      <TabbedShowLayout>
        <Tab label="Wallet">
          <TextField source="id" />
          <ReferenceField source="userId" reference="users" link="show">
            <TextField source="name" />
          </ReferenceField>
          <CurrencyField source="amount" />
          <BooleanField source="isBlocked" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
        </Tab>
        <Tab label="Transactions">
          <ReferenceManyField
            source="id"
            reference="transactions"
            target="walletId"
            filter={TransactionFilters}
            addLabel={false}
            sort={{ field: 'updatedAt', order: 'DESC' }}
          >
            <TransactionTab {...props} />
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
