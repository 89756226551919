import { Dialog, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import {
  Button,
  Confirm,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  NumberField,
  NumberInput,
  RecordContextProvider,
  ReferenceField,
  ReferenceManyField,
  required,
  SaveButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  Toolbar,
  TopToolbar,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import { v4 as uuidv4 } from 'uuid';
import authProvider from '../../ReactAdmin/authProvider';
import CurrencyField from '../Custom/CurrencyField';

const AddContractPayment = () => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();
  const [create, { isLoading: isCreateLoading }] = useCreate('contractPayments');

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      create(
        'ContractPayments',
        {
          data: {
            id: uuidv4(),
            expectedAmount: value.expectedAmount,
            actualAmount: value.actualAmount,
            totalLessons: value.totalLessons,
            learningContractId: record.id,
            expectedTime: value.expectedTime,
            actualTime: value.actualTime,
            lastStage: value.lastStage,
          },
        },
        {
          onSuccess: () => {
            notify('Create Learning contract successfully.', 'success');
            setShowDialog(false);
            setLoading(false);
            refresh();
          },
          onError: ({ err }) => {
            notify(err.message, 'error');
            setShowDialog(false);
            setLoading(false);
          },
        },
      );

      setShowDialog(false);
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const SaveToolbar = () => (
    <Toolbar>
      <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
        <IconCancel />
      </Button>
      <SaveButton disabled={isLoading} />
    </Toolbar>
  );
  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          setShowDialog(true);
        }}
      >
        Add Contract Payment
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Add Contract Payment"
      >
        <DialogTitle>Add Contract Payment</DialogTitle>
        <RecordContextProvider>
          <SimpleForm onSubmit={handleSubmit} toolbar={<SaveToolbar />}>
            <NumberInput source="expectedAmount" validate={required()} fullWidth />
            <NumberInput source="actualAmount" validate={required()} fullWidth />
            <NumberInput source="totalLessons" validate={required()} fullWidth />
            <DateTimeInput source="expectedTime" validate={required()} />
            <DateTimeInput source="actualTime" validate={required()} />

            <SelectInput
              alwaysOn
              source="lastStage"
              choices={[
                { id: 'OPEN', name: 'Open' },
                { id: 'PAID', name: 'Paid' },
              ]}
              optionText="name"
              optionValue="id"
            />
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

const LearningContractActions = () => {
  const record = useRecordContext();
  const role = authProvider.getRole();
  return (
    <TopToolbar>
      {record && record.lastStage !== 'FULLY_PAID' && (
        <>
          <AddContractPayment />
        </>
      )}
    </TopToolbar>
  );
};

export default (props) => (
  <Show {...props} actions={<LearningContractActions />}>
    <TabbedShowLayout>
      <Tab label="Contract">
        <SimpleShowLayout>
          <ReferenceField source="userId" reference="users" label="Student" link="show">
            <TextField source="name" />
          </ReferenceField>
          <CurrencyField source="totalFee" />
          <TextField source="totalLessons" />
          <TextField source="bonusLessons" />
          <CurrencyField source="discount" />
          <SelectField
            source="lastStage"
            choices={[
              { id: 'NEW', name: 'New' },
              { id: 'FULLY_PAID', name: 'Fully Paid' },
              { id: 'PARTIAL_PAID', name: 'Partial Paid' },
              { id: 'CANCELLED', name: 'Cancelled' },
            ]}
            optionText="name"
            optionValue="id"
          />
          <TextField source="type" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </SimpleShowLayout>
      </Tab>
      <Tab label="Payments">
        <ReferenceManyField
          reference="contractPayments"
          target="learningContractId"
          addLabel={false}
          sortBy='updatedAt'
          sortByOrder='DESC'
        >
          <Datagrid
            rowClick={(id, basePath, record) => `/contractPayments/${record.id}/show`}
          >
            <ReferenceField
              source="learningContractId"
              reference="learningContracts"
              label="Student"
            >
              <ReferenceField source="userId" reference="users">
                <TextField source="name" />
              </ReferenceField>
            </ReferenceField>
            <CurrencyField source="expectedAmount" />
            <CurrencyField source="actualAmount" />
            <NumberField source="totalLessons" />
            <DateField source="expectedTime" showTime />
            <DateField source="actualTime" showTime />

            <SelectField
              alwaysOn
              source="lastStage"
              choices={[
                { id: 'OPEN', name: 'Open' },
                { id: 'PAID', name: 'Paid' },
                { id: 'APPROVED', name: 'Approved' },
                { id: 'CANCELLED', name: 'Cancelled' },
              ]}
              optionText="name"
              optionValue="id"
            />
            <DateField source="createdAt" showTime />
            <DateField source="updatedAt" showTime />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
