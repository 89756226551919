import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  BooleanField,
  NullableBooleanInput,TextInput
} from 'react-admin';

const staffListFilter = [
  <NullableBooleanInput source='visible' alwaysOn />,
  <TextInput source='name' alwaysOn/>
];

export default (props) => (
  <List {...props} filters={staffListFilter}>
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <ReferenceField source='userId' reference='users'>
        <TextField source='name' />
      </ReferenceField>
      <ReferenceField source='userId' reference='users' label='Email'>
        <TextField source='email' />
      </ReferenceField>
      <TextField source='callerNumber' />
      <TextField source='name' />
      <TextField source='zohoTagId' />
      <TextField source='startWorkingDay' />
      <TextField source='endWorkingDay' />
      <TextField source='zohoAccount' />
      <TextField source='role' />
      <BooleanField source='visible' />
    </Datagrid>
  </List>
);
