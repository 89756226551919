import React, { useState } from 'react';
import {
  Button,
  Confirm,
  DateField,
  Edit,
  EditButton,
  NumberField,
  NumberInput,
  RecordContextProvider,
  ReferenceField,
  required,
  SaveButton,
  SelectField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  Toolbar,
  TopToolbar,
  useCreate,
  useNotify,
  useRecordContext,
  useUpdate,
} from 'react-admin';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import IconCancel from '@material-ui/icons/Cancel';
import authProvider from '../../ReactAdmin/authProvider';
import Config from '../../Config';
import CurrencyField from '../Custom/CurrencyField';

const axios = require('axios');

const ApproveButton = ({ paymentId }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const notify = useNotify();

  const approve = () => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    axios
      .post(
        `${Config.apiUrl}/student/approve-payment`,
        { paymentId },
        { headers: { Authorization: `Bearer ${auth.accessToken}` } },
      )
      .then((response) => {
        notify('Successfully approve contract payment');
      })
      .catch((error) => {
        notify(error.message, 'error');
      });
  };

  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Approve
      </Button>
      <Confirm
        isOpen={isOpen}
        title="Do you want to approve this Contract Payment?"
        onConfirm={() => {
          setIsOpen(false);
          approve();
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        confirm="Yes"
        cancel="No"
      />
    </>
  );
};

const PaidButton = () => {
  const record = useRecordContext();

  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const [update, { updateLoading }] = useUpdate('contractPayments');

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = (value) => {
    const lastStage = record.expectedAmount <= value.actualAmount ? 'PAID' : 'OPEND';
    update(
      'contractPayments',
      {
        id: record.id,
        data: {
          actualAmount: value.actualAmount,
          lastStage,
        },
      },
      {
        onSuccess: () => {
          notify('Update payment successfully', 'info');
          setShowDialog(false);
        },
        onError: ({ error }) => {
          notify(error.message, 'error');
        },
      },
    );
  };

  const SaveToolbar = () => (
    <Toolbar>
      <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={updateLoading}>
        <IconCancel />
      </Button>
      <SaveButton disabled={updateLoading} />
    </Toolbar>
  );

  return (
    <>
      {record.actualAmount < record.expectedAmount && (
        <Button
          onClick={() => {
            setShowDialog(true);
          }}
        >
          PAID
        </Button>
      )}
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Update Payment">
        <DialogTitle>Update Actual Amount</DialogTitle>
        <RecordContextProvider>
          <SimpleForm onSubmit={handleSubmit} toolbar={<SaveToolbar />}>
            <NumberInput source="actualAmount" validate={required()} fullWidth />
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

const PaymentActions = () => {
  const record = useRecordContext();
  const role = authProvider.getRole();
  return (
    <TopToolbar>
      {record && record.lastStage !== 'APPROVED' && <ApproveButton paymentId={record.id} />}
      {record && <PaidButton />}
      {record && <EditButton/> }
    </TopToolbar>
  );
};

export default (props) => (
  <Show {...props} actions={<PaymentActions />}>
    <SimpleShowLayout>
      <ReferenceField source="learningContractId" reference="learningContracts" label="Student">
        <ReferenceField source="userId" reference="users">
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField
        source="learningContractId"
        reference="learningContracts"
        label="Contract ID"
        link="show"
      >
        <TextField source="id" />
      </ReferenceField>
      <CurrencyField source="expectedAmount" />
      <CurrencyField source="actualAmount" />
      <NumberField source="totalLessons" />
      <DateField source="expectedTime" showTime />
      <DateField source="actualTime" showTime />
      <SelectField
        alwaysOn
        source="lastStage"
        choices={[
          { id: 'OPEN', name: 'Open' },
          { id: 'PAID', name: 'Paid' },
          { id: 'APPROVED', name: 'Approved' },
          { id: 'CANCELLED', name: 'Cancelled' },
        ]}
        optionText="name"
        optionValue="id"
      />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  </Show>
);
