import Box from '@mui/material/Grid';
import React, { useState } from 'react';
import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  EmailField,
  Form,
  Labeled,
  NumberField,
  RecordContextProvider,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SaveButton,
  SelectInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useCreate,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { v4 as uuidv4 } from 'uuid';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import IconCancel from '@material-ui/icons/Cancel';

import authProvider from '../../ReactAdmin/authProvider';
import CreateNoteButton from '../User/CreateNoteButton';

const axios = require('axios');

const UserShowTitle = () => {
  const record = useRecordContext();
  return record ? <>{` ${record.fullName}${record.phone ? ` (${record.phone})` : ''}`}</> : null;
};

const UserShowActions = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [create, { isLoading: isCreateLoading }] = useCreate('trialRequest');

  const updateStage = (value) => {
    setLoading(true);
    const userAuth = JSON.parse(localStorage.getItem('auth'));
    try {
      create(
        'resourceStages',
        {
          data: {
            id: uuidv4(),
            resourceName: 'Talents',
            resourceId: record.id,
            stageId: value.resource,
            createdBy: userAuth.user.id,
          },
        },
        {
          onSuccess: () => {
            notify('Update stage successfully.', 'success');
            setShowDialog(false);
          },
          onError: ({ err }) => {
            notify(err.message, 'error');
            setShowDialog(false);
          },
        },
      );

      if (value.note) {
        const auth = JSON.parse(localStorage.getItem('auth'));
        create(
          'notes',
          {
            data: {
              id: uuidv4(),
              resourceId: record.id,
              resourceName: 'Users',
              ownerId: auth.user.id,
              note: value.note,
            },
          },
          {
            onSuccess: () => {
              setShowDialog(false);
            },
            onError: ({ err }) => {
              notify(err.message, 'error');
              setShowDialog(false);
            },
          },
        );
      }
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const role = authProvider.getRole();
  return (
    <TopToolbar>
      <Button color="primary" onClick={() => setShowDialog(true)}>
        Update Stage
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Update Stage">
        <DialogTitle>Update Stage</DialogTitle>
        <RecordContextProvider>
          <SimpleForm
            onSubmit={updateStage}
            toolbar={
              <Toolbar style={{ justifyContent: 'flex-end' }}>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
                  <IconCancel />
                </Button>
                <SaveButton disabled={isLoading} />
              </Toolbar>
            }
          >
            <ReferenceInput source="resource" reference="stages">
              <SelectInput optionText="name" optionValue="id" fullWidth />
            </ReferenceInput>
            <TextInput source="note" fullWidth multiline inputProps={{ maxLength: 10240 }} />
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </TopToolbar>
  );
};

export default (props) => {
  const record = useRecordContext();
  return (
    <Show title={<UserShowTitle />} actions={<UserShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="Account">
          <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(3, 1fr)', width: 1 }}>
            <Labeled label="Id">
              <TextField source="id" />
            </Labeled>
            <Labeled label="Email">
              <EmailField source="email" />
            </Labeled>
            <Labeled label="Fullname">
              <TextField source="fullName" />
            </Labeled>
          </Box>
          <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(3, 1fr)', width: 1 }}>
            <Labeled label="Phone">
              <TextField source="phone" />
            </Labeled>
            <Labeled label="BirthYear">
              <NumberField source="birthYear" />
            </Labeled>
            <Labeled label="Address">
              <TextField source="address" />
            </Labeled>
          </Box>
          <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(3, 1fr)', width: 1 }}>
            <Labeled label="Desired Salary">
              <NumberField source="desiredSalary" />
            </Labeled>

            <Labeled label="Own Laptop">
              <BooleanField source="ownLaptop" />
            </Labeled>
            <Labeled label="Position">
              <TextField source="position" />
            </Labeled>
          </Box>
          <TextField source="experience" />

          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <ReferenceField reference="talentStageViews" source="id" label="Stage">
            <TextField source="name" />
          </ReferenceField>
        </Tab>
        <Tab label="Stage history">
          <ReferenceManyField reference="resourceStages" target="resourceId">
            <Datagrid rowClick="show" sort={{ field: 'createdAt', order: 'ASC' }}>
              <ReferenceField reference="stage" source="stageId" label="Stage">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField reference="users" source="createdBy" label="Created By">
                <TextField source="name" />
              </ReferenceField>
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Notes">
          <Box>
            <CreateNoteButton userId={record?.id} resourceName="Talents" />
          </Box>
          <ReferenceManyField reference="notes" target="resourceId">
            <Datagrid>
              <TextField source="note" />
              <ReferenceField source="ownerId" reference="users">
                <TextField source="name" />
              </ReferenceField>
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
