import React, { useState } from 'react';
import {
  Button,
  useRecordContext,
  Form,
  SaveButton,
  RecordContextProvider,
  SimpleForm,
  AutocompleteInput,
  DateTimeInput,
  NumberInput,
  ReferenceInput,
  Toolbar,
  useNotify,
} from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import bookingService from './Business/bookingService';

const BookRecurringScheduleButton = () => {
  const record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleSubmit = async ({ tutorId, startTime, bookingCount }) => {
    try {
      setLoading(true);
      await bookingService.weeklyBooking(record.id, tutorId, startTime, bookingCount);
      notify('Weekly booking completed', { type: 'success' });
      setShowDialog(false);
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const RecurringBookingToolbar = () => (
    <Toolbar>
      <SaveButton label="Book" disabled={isLoading}/>
    </Toolbar>
  );

  const validateRecurringBooking = ({ tutorId, startTime, bookingCount }) => {
    const errors = {};
    if (!tutorId) errors.tutorId = 'No tutor selected';
    if (!startTime || startTime < Date.now() + 2 * 60 * 60 * 1000)
      errors.startTime = 'Booking time must after 2 hours from now';
    if (!Number.isInteger(bookingCount) || bookingCount < 1)
      errors.bookingCount = 'Number of booking must at least 1';
    return errors;
  };

  return (
    <>
      <Button onClick={handleClick}>Book weekly schedule</Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Book weekly schedule"
      >
        <DialogTitle>Book weekly schedule</DialogTitle>
        <RecordContextProvider>
          <SimpleForm
            onSubmit={handleSubmit}
            toolbar={<RecurringBookingToolbar />}
            validate={validateRecurringBooking}
          >
            <ReferenceInput
              source="tutorId"
              reference="tutorViews"
              filter={{ isActivated: true }}
              sort={{ field: 'name', order: 'ASC' }}
              perPage={5000}
            >
              <AutocompleteInput optionText="name" optionValue="userId" fullWidth />
            </ReferenceInput>
            <DateTimeInput source="startTime" minDateTime={new Date()} min={new Date()} />
            <NumberInput source="bookingCount" />
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

export default BookRecurringScheduleButton;
