import React, { useState } from 'react';
import {
  Button,
  Form,
  required,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  useUpdate,
} from 'react-admin';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { v4 as uuidv4 } from 'uuid';

const WithdrawButton = ({ walletId, amount }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { isLoading: isCreateLoading }] = useCreate('transactions');
  const [update, { isLoading: isUpdateLoading, error }] = useUpdate('wallets');
  const notify = useNotify();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      'transactions',
      {
        data: {
          id: uuidv4(),
          walletId,
          price: -values.price,
          note: values.note,
          status: 'success',
          type: 'withdraw',
          balance: parseInt(amount, 10) - parseInt(values.price, 10)
        },
      },
      {
        onSuccess: () => {
          update(
            'wallets',
            {
              id: walletId,
              data: {
                amount: parseInt(amount, 10) - parseInt(values.price, 10),
              },
              previousData: {
                amount,
              },
            },
            {
              onSuccess: () => {
                notify('Pay out successful', 'info');
                setShowDialog(false);
              },
              onError: ({ err }) => {
                notify(err.message, 'error');
              },
            },
          );
        },
        onError: ({ err }) => {
          notify(err.message, 'error');
        },
      },
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="Withdraw" startIcon={<RemoveCircleIcon />} />
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Withdraw">
        <DialogTitle>Withdraw</DialogTitle>

        <Form onSubmit={handleSubmit}>
          <DialogContent>
            <TextInput source="price" validate={required()} fullWidth />
            <TextInput source="note" validate={required()} fullWidth />
          </DialogContent>
          <DialogActions>
            <Button
              label="ra.action.cancel"
              onClick={handleCloseClick}
              disabled={isCreateLoading || isUpdateLoading}
            >
              <IconCancel />
            </Button>
            <SaveButton />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

export default WithdrawButton;
