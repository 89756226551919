import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  DateInput,
  BooleanInput,
  TextField,
  SelectInput,
  AutocompleteInput,
  NumberInput,
} from 'react-admin';
import React from 'react';
import Box from '@mui/material/Grid';

export default (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="userId" reference="users" perPage={10000}>
        <Box sx={{ minWidth: 300 }}>
          <AutocompleteInput optionText="email" />
        </Box>
      </ReferenceInput>
      <TextInput source="callerNumber" />
      <TextInput source="name" />
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(4, 1fr)', width: 1 }}>
        <NumberInput source="kpiCall" />
        <NumberInput source="kpiTrial" />
        <NumberInput source="kpiNcc" />
        <NumberInput source="kpiStudents" />
      </Box>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
        <TextInput source="zohoAccount" />
        <TextInput source="zohoTagId" />
      </Box>
      <Box>
        <DateInput source="startWorkingDay" />
        <DateInput source="endWorkingDay" />
      </Box>
      <TextInput source="role" />
      <BooleanInput source="visible" />
    </SimpleForm>
  </Edit>
);
