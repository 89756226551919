import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import {
  Button,
  NumberInput,
  RecordContextProvider,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import { v4 as uuidv4 } from 'uuid';
import authProvider from '../../ReactAdmin/authProvider';

export default ({ userId }) => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();
  const [create, { isLoading: isCreateLoading }] = useCreate('notes');

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      create(
        'LearningContracts',
        {
          data: {
            id: uuidv4(),
            totalFee: value.totalFee,
            discount: value.discount,
            totalLessons: value.totalLessons,
            bonusLessons: value.bonusLessons,
            lastStage: "NEW",
            type: value.type,
            userId : record.id
          },
        },
        {
          onSuccess: () => {
            notify('Create Learning contract successfully.', 'success');
            setShowDialog(false);
            setLoading(false);
            refresh();
          },
          onError: ({ err }) => {
            notify(err.message, 'error');
            setShowDialog(false);
            setLoading(false);
          },
        },
      );

      setShowDialog(false);
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const SaveToolbar = () => (
    <Toolbar>
      <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
        <IconCancel />
      </Button>
      <SaveButton disabled={isLoading} />
    </Toolbar>
  );

  return (
    <>
      <Button onClick={handleClick}>Add Learning Contract</Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Add Learning Contract"
      >
        <DialogTitle>Add Learning Contract</DialogTitle>
        <RecordContextProvider>
          <SimpleForm onSubmit={handleSubmit} toolbar={<SaveToolbar />}>
            <NumberInput source="totalFee" validate={required()} fullWidth />
            <NumberInput source="discount" validate={required()} fullWidth />
            <NumberInput source="totalLessons" validate={required()} fullWidth />
            <NumberInput source="bonusLessons" validate={required()} fullWidth />
            <SelectInput
              source="type"
              allowEmpty
              choices={[{ name: 'New' }, { name: 'Renew' }]}
              optionValue="name"
            />
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};
