import { DateField, ReferenceField, Show, Labeled, SimpleShowLayout, TextField } from 'react-admin';
import Box from '@mui/material/Grid';
import CurrencyField from '../Custom/CurrencyField';

export default (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="walletId" reference="wallets" link="show">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField source="bookingId" reference="bookings">
        <TextField source="id" />
      </ReferenceField>
      <CurrencyField source="price" />
      <CurrencyField source="revenue" />
      <ReferenceField source="ledgerId" reference="ledgers">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="status" />
      <TextField source="type" />
      <TextField source="note" />
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(3, 1fr)', width: 1 }}>
        <Labeled label="Sale by">
          <ReferenceField source="saleByStaffId" reference="staffs">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>
        <Labeled label="Deal type">
        <TextField source="dealType" />
        </Labeled>
        <Labeled label="Sale at">
        <DateField source="saleAt" />
        </Labeled>
      </Box>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  </Show>
);
