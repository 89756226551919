import React from 'react';
import { DateTimeInput, Edit, NumberInput, required, SelectInput, SimpleForm } from 'react-admin';

export default (props) => (
  <Edit {...props}>
    <SimpleForm>
      <NumberInput source="expectedAmount" validate={required()} />
      <NumberInput source="actualAmount" validate={required()}  />
      <NumberInput source="totalLessons" validate={required()}  />
      <DateTimeInput source="expectedTime" validate={required()} />
      <DateTimeInput source="actualTime" validate={required()} />
      <SelectInput
        alwaysOn
        source="lastStage"
        choices={[
          { id: 'OPEN', name: 'Open' },
          { id: 'PAID', name: 'Paid' },
          { id: 'APPROVED', name: 'Approved' },
          { id: 'CANCELLED', name: 'Cancelled' },
        ]}
        optionText="name"
        optionValue="id"
      />
    </SimpleForm>
  </Edit>
);
