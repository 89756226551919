import Config from '../Config';

const authProvider = {
  // authentication
  login: ({ username, password }) => {
    const request = new Request(`${Config.apiUrl}/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        localStorage.setItem('auth', JSON.stringify(auth));
        // Reload page to correctly load pgDataProvider in App.js
        window.location.replace('/');
      });
    // .catch((e) => {
    //   throw new Error('Network error');
    // });
  },
  checkError: (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject({ redirectTo: '/login' });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  // Called when click on main menu items
  checkAuth: () =>
    localStorage.getItem('auth') ? Promise.resolve() : Promise.reject({ redirectTo: '/login' }),
  // checkAuth: () => Promise.resolve(),
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const { id, username } = JSON.parse(localStorage.getItem('auth'));
      return Promise.resolve({ id, fullName: username, avatar: 'https://i.pravatar.cc/300' });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // authorization
  getPermissions: () => {
    const auth = localStorage.getItem('auth');
    if (auth) {
      const { permissions } = JSON.parse(auth);
      return Promise.resolve(permissions);
    }
    return Promise.reject();
  },

  // Custom functions
  getRole: () => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    return auth && auth.user && auth.user.role;
  },
  getAccessToken: () => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    return auth && auth.accessToken;
  },
};

export default authProvider;
