import {
  Confirm,
  EditButton,
  Show,
  TopToolbar,
  ReferenceField,
  TextField,
  BooleanField,
  DateField,
  useRecordContext,
  WithRecord,
  TabbedShowLayout,
  Tab,
  Labeled,
  NumberField,
  useGetManyReference,
  useNotify,
  useUpdate,
  RecordContextProvider,
  SimpleForm,
  Toolbar,
  ReferenceInput,
  SelectInput,
  TextInput,
  SaveButton,
  ReferenceManyField,
  Datagrid,
} from 'react-admin';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Box, Dialog, DialogTitle, Grid, Link, Pagination } from '@mui/material';
import IconCancel from '@material-ui/icons/Cancel';
import Config from '../../Config/index';
import LinkField from '../Custom/LinkField';
import ExistBooleanField from '../Custom/ExistBooleanField';

const axios = require('axios');

function cancelBooking(bookingId) {
  const auth = JSON.parse(localStorage.getItem('auth'));
  axios
    .post(
      `${Config.apiUrl}/booking/cancel`,
      { bookingId },
      { headers: { Authorization: `Bearer ${auth.accessToken}` } },
    )
    .then((response) => {})
    .catch((error) => {
      console.log(error);
    });
}

const CancelBookingButton = ({ bookingId }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Cancel booking
      </Button>
      <Confirm
        isOpen={isOpen}
        title="Do you want to cancel this booking?"
        onConfirm={() => {
          cancelBooking(bookingId);
          setIsOpen(false);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        confirm="Yes"
        cancel="No"
      />
    </>
  );
};

const UpdateLessonStatus = ({ id }) => {
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [update, { isLoading: isCreateLoading }] = useUpdate('classReviews');

  const updateLessonStatus = (value) => {
    setLoading(true);
    setShowDialog(false);
    try {
      update(
        'classReviews',
        {
          id,
          data: {
            lessonStatusId: value.lessonStatusId,
          },
        },
        {
          onSuccess: () => {
            notify('Update class review successfully.', 'success');
            setShowDialog(false);
          },
          onError: ({ err }) => {
            notify(err.message, 'error');
            setShowDialog(false);
          },
        },
      );
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Button color="primary" onClick={() => setShowDialog(true)}>
        Update Lesson Status
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Update Lesson Status"
      >
        <DialogTitle>Update Lesson Status </DialogTitle>
        <RecordContextProvider>
          <SimpleForm
            onSubmit={updateLessonStatus}
            toolbar={
              <Toolbar style={{ justifyContent: 'flex-end' }}>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
                  <IconCancel />
                </Button>
                <SaveButton disabled={isLoading} />
              </Toolbar>
            }
          >
            <ReferenceInput source="lessonStatusId" reference="classReviewStatuses">
              <SelectInput optionText="status" optionValue="id" fullWidth />
            </ReferenceInput>
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

const BookingShowActions = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      {record && (
        <>
          <EditButton />
          <CancelBookingButton bookingId={record.id} />
        </>
      )}
    </TopToolbar>
  );
};

const ClassReviewTab = () => {
  const record = useRecordContext();
  const { data, loading, error } = useGetManyReference('classReviews', {
    target: 'bookingId',
    id: record.id,
    filter: {
      bookingId: record.id,
    },
  });
  if (!data || data.length === 0) return null;

  return (
    <>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(3, 2fr)', width: 1 }}>
        <UpdateLessonStatus id={data[0].id} />
      </Box>

      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}>
        <Labeled label="Book Name">
          <TextField source="book" record={data[0]} />
        </Labeled>
        <Labeled label="Lesson Status">
          <ReferenceField record={data[0]} reference="classReviewStatuses" source="lessonStatusId">
            <TextField source="status" />
          </ReferenceField>
        </Labeled>
        <Labeled label="Unit">
          <NumberField source="unit" record={data[0]} />
        </Labeled>

        <Labeled label="Lesson">
          <TextField source="lesson" record={data[0]} />
        </Labeled>
        <Labeled label="Lesson Progress">
          <TextField source="lessonProgress" record={data[0]} />
        </Labeled>
      </Box>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
        <Labeled label="Behavioir Rating">
          <NumberField source="behaviorRating" record={data[0]} />
        </Labeled>
        <Labeled label="Behavioir Comment">
          <TextField source="behaviorComment" record={data[0]} />
        </Labeled>
        <Labeled label="Listening Rating">
          <NumberField source="listeningRating" record={data[0]} />
        </Labeled>
        <Labeled label="Listening Comment">
          <TextField source="listeningComment" record={data[0]} />
        </Labeled>
      </Box>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
        <Labeled label="Speaking Rating">
          <NumberField source="speakingRating" record={data[0]} />
        </Labeled>
        <Labeled label="Speaking Comment">
          <TextField source="speakingComment" record={data[0]} />
        </Labeled>
        <Labeled label="Vocabulary Rating">
          <NumberField source="listeningRating" record={data[0]} />
        </Labeled>

        <Labeled label="Vocabulary Comment">
          <TextField source="vocabularyComment" record={data[0]} />
        </Labeled>
      </Box>
      <Box>
        <Labeled label="Homework Comment">
          <TextField source="homeworkComment" record={data[0]} />
        </Labeled>
      </Box>
    </>
  );
};

const BookingShow = (props) => {
  const { id } = props;

  return (
    <Show actions={<BookingShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="Booking Detail">
          <ReferenceField source="userId" reference="users">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="scheduleDetailId" reference="scheduleDetails">
            <TextField source="id" />
          </ReferenceField>
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
          <BooleanField source="isDeleted" />
          <BooleanField source="isTrial" />
          <LinkField source="tutorMeetingLink" prefix={Config.appUrl} text="Tutor lesson link" />
          <LinkField
            source="studentMeetingLink"
            prefix={Config.appUrl}
            text="Student lesson link"
          />
          <LinkField source="googleMeetLink" text="Google meet link" />
          <WithRecord
            label="Trial instruction"
            render={(record) =>
              record &&
              record.isTrial &&
              record.googleMeetLink && (
                <Link
                  href={`${process.env.REACT_APP_APP_URL}/trial-instruction/${record.id}`}
                  target="_blank"
                >
                  Trail instruction
                </Link>
              )
            }
          />
          <TextField source="studentRequest" component="pre" />
          <TextField source="status" />
          <TextField source="recordUrl" />
          <NumberField source="convertedLesson" />
        </Tab>
        <Tab label="Class Review">
          <ClassReviewTab />
        </Tab>
        <Tab label="Attendances">
          <ReferenceManyField
            reference="bookingAttendances"
            target="bookingId"
            addLabel={false}
            sort={{ field: 'createdAt', order: 'DESC' }}
          >
            <Datagrid
            >
              <TextField source="id" />
              <ReferenceField source="userId" reference="users" label="User">
                <TextField source="name" />
              </ReferenceField>
              <ExistBooleanField source="isJoin" />
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
              <TextField source="socketId" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default BookingShow;
