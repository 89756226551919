import {
    Create,
    SimpleForm,
    TextInput
} from 'react-admin';
import Box from '@mui/material/Grid';
import React from 'react';

export default (props) => (
    <Create {...props}>
        <SimpleForm>
            <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
                <TextInput source="key" />
                <TextInput source="value" />
                <TextInput source="language" />
            </Box>
        </SimpleForm>
    </Create>
);
