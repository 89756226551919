import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Create, SimpleForm, SelectInput, TextInput, NumberInput, required } from 'react-admin';

export default (props) => (
  <Create {...props}>
    <SimpleForm>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
        <TextInput source="id" defaultValue={uuidv4()} disabled hidden />
        <TextInput source="name" validate={required()} />
        <SelectInput
          source="resource"
          choices={[
            { name: 'Talents' },
            { name: 'Users' },
            { name: 'Bookings' },
            { name: 'Students' },
          ]}
          validate={required()}
          optionValue="name"
        />
        <NumberInput min={0} inputMode="numeric" source="order" validate={required()} />
      </Box>
    </SimpleForm>
  </Create>
);
