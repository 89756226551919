import { Datagrid, DateField, List, NumberField, ReferenceField, TextField } from 'react-admin';

export default () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="courseId" reference="courses"><TextField source="name" /></ReferenceField>
      <TextField source="name" />
      <DateField source="description" />
      <NumberField source="orderCourse" />
      <TextField source="videoUrl" />
      <TextField source="nameFile" />
      <TextField source="type" />
      <TextField source="numberOfPages" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
