import React from 'react';
import {
  Datagrid,
  DateField,
  EmailField,
  ImageField,
  List,
  NumberField,
  TextField,
  TextInput,
  NullableBooleanInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Button,
  AutocompleteInput,
  TopToolbar,
} from 'react-admin';
import ExistBooleanField from '../Custom/ExistBooleanField';
import CurrencyField from '../Custom/CurrencyField';
import RegisterStudent from './RegisterStudent';
import authProvider from '../../ReactAdmin/authProvider';

const userListFilters = [
  <TextInput source="name" alwaysOn />,
  <TextInput source="email" alwaysOn />,
  <TextInput source="phone" alwaysOn />,
  <ReferenceInput source="saleByStaffId" reference="staffs" alwaysOn perPage={10000}>
    <AutocompleteInput optionText="name" allowEmpty resettable />
  </ReferenceInput>,
  <ReferenceInput source="caredByStaffId" reference="staffs" alwaysOn perPage={10000}>
    <AutocompleteInput optionText="name" allowEmpty resettable />
  </ReferenceInput>,
  <NullableBooleanInput source="isActivated" alwaysOn />,
];

const partnerUserListFilters = [
  <TextInput source="name" alwaysOn />,
  <TextInput source="email" alwaysOn />,
  <TextInput source="phone" alwaysOn />,
  <NullableBooleanInput source="isActivated" alwaysOn />,
];

const StudentListActions = () => (
  <TopToolbar>
    <RegisterStudent />
  </TopToolbar>
);

export default (props) => {
  const role = authProvider.getRole();
  return (
    <List
      {...props}
      filters={role === 'lettutor_partner' ? partnerUserListFilters : userListFilters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      actions={<StudentListActions />}
      bulkActionButtons={null}
      empty={false}
    >
      <Datagrid rowClick={(id, basePath, record) => `/users/${record.id}/show`}>
        <ImageField source="avatar" sx={{ width: 40, height: 40 }} />
        <TextField source="name" />
        <TextField source="phone" />
        <ExistBooleanField source="isPhoneActivated" />
        <ExistBooleanField source="isActivated" />
        <EmailField source="email" />
        <NumberField source="bookingCount" options={{ style: 'percent' }} />
        <CurrencyField source="balance" />
        {!role && <CurrencyField source="totalRevenue" textAlign="right" />}
        {role !== 'lettutor_partner' && [
          <TextField source="referral" />,
          <ReferenceField source="saleByStaffId" reference="staffs">
            <TextField source="name" />
          </ReferenceField>,
          <ReferenceField source="caredByStaffId" reference="staffs">
            <TextField source="name" />
          </ReferenceField>,
        ]}
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};
