import {
  AutocompleteInput,
  Create,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  SelectInput,
  Edit,
} from 'react-admin';

export default () => (
  <Edit>
    <SimpleForm>
      <ReferenceInput source="userId" reference="users" perPage={5000}>
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput source="roleId" reference="roles">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
