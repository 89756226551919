import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  SelectField,
  SelectInput,
  TextField,
} from 'react-admin';
import CurrencyField from '../Custom/CurrencyField';

const Filter = [
  <SelectInput
    alwaysOn
    source="lastStage"
    choices={[
      { id: 'OPEN', name: 'Open' },
      { id: 'PAID', name: 'Paid' },
      { id: 'APPROVED', name: 'Approved' },
      { id: 'CANCELLED', name: 'Cancelled' },
    ]}
    optionText="name"
    optionValue="id"
  />,
];
export default (props) => (
  <List filters={Filter} {...props}>
    <Datagrid rowClick={(id, basePath, record) => `/contractPayments/${record.id}/show`} sort={{ field: 'updatedAt', order: 'DESC' }}>
      <ReferenceField source="learningContractId" reference="learningContracts" label="Student">
        <ReferenceField source="userId" reference="users" >
          <TextField source="name"  />
        </ReferenceField>
      </ReferenceField>
      <CurrencyField source="expectedAmount" />
      <CurrencyField source="actualAmount" />
      <NumberField source="totalLessons" />
      <DateField source="expectedTime" showTime />
      <DateField source="actualTime" showTime />

      <SelectField
        alwaysOn
        source="lastStage"
        choices={[
          { id: 'OPEN', name: 'Open' },
          { id: 'PAID', name: 'Paid' },
          { id: 'APPROVED', name: 'Approved' },
          { id: 'CANCELLED', name: 'Cancelled' },
        ]}
        optionText="name"
        optionValue="id"
      />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
